import { useRef, useState, useEffect } from "react"
import { useAddNewUserMutation } from "../features/users/usersApiSlice"
import { useLoginMutation } from '../features/auth/authApiSlice'
import { setCredentials } from '../features/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import useTitle from "../hooks/useTitle"
import '../multiForms.css'

const USER_REGEX = /^[A-z0-9.!@#$%^_+{}:;"'<>/?~`&*()-]{3,50}$/
const PWD_REGEX = /^[A-z0-9.!@#$%^_+{}:;"'<>/?~`&*()-]{4,100}$/

const NewUserForm = () => {
    useTitle('Volleysub')


    const userRef = useRef()
    const errRef = useRef()
    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])



    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(["Player"])
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [region, setRegion] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [sub, setSub] = useState(false)
    const [hitterSkillLevel, setHitterSkillLevel] = useState([])
    const [passerSkillLevel, setPasserSkillLevel] = useState([])
    const [setterSkillLevel, setSetterSkillLevel] = useState([])

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess) {
            setUsername('')
            setPassword('')
            setRoles([])
            setSub(false)
            setHitterSkillLevel([])
            setPasserSkillLevel([])
            setSetterSkillLevel([])
            setAddress()
            setCity()
            navigate('/dash/users')
        }
    }, [isSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value.toLowerCase())
    const onPasswordChanged = e => setPassword(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)
    const onFirstNameChanged = e => setFirstName(e.target.value)
    const onLastNameChanged = e => setLastName(e.target.value)
    const onGenderChanged = e => setGender(e.target.value)
    const onRegionChanged = e => setRegion(e.target.value)


    const canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading

    const [login] = useLoginMutation()

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ username, password, roles, email, firstName, lastName, gender, address, city, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel })
        }
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            navigate('/dash')
        } catch (err) {
            if (!err.status) {
                isError('No Server Response');
            } else if (err.status === 400) {
                isError('Missing Username or Password');
            } else if (err.status === 401) {
                isError('Unauthorized');
            } else {
                isError(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"


    const content = (
        <>
        <VSLoginHeader />
        <div className="PlayerSignUpFormContainer">
            <p className={errClass}>{error?.data?.message}</p>
            <h1 className="PlayerSignUpTitle">Signup</h1>
            <form className="PlayerSignUpForm" onSubmit={onSaveUserClicked}>
                <label>
                    Username 
                <input
                    id="username"
                    name="username"
                    type="text"
                    ref={userRef}
                    value={username}
                    onChange={onUsernameChanged}
                    required
                />
                </label>

                <label>
                    Password
                <input
                    id="password"
                    name="password"
                    type="password"
                    ref={userRef}
                    value={password}
                    onChange={onPasswordChanged}
                    required
                />
                </label>
                <label>
                    Email 
                <input
                    id="Email"
                    name="Email"
                    type="email"
                    ref={userRef}
                    value={email}
                    onChange={onEmailChanged}
                    required
                />
                </label>
                <label>
                    First Name 
                <input
                    id="firstName"
                    name="First Name"
                    type="text"
                    ref={userRef}
                    value={firstName}
                    onChange={onFirstNameChanged}
                    required
                />
                </label>
                <label>
                    Last Name 
                <input
                    id="lastName"
                    name="Last Name"
                    type="text"
                    ref={userRef}
                    value={lastName}
                    onChange={onLastNameChanged}
                    required
                />
                </label>
                <label>
                    Gender
                    <select
                    value={gender}
                    onChange={onGenderChanged}
                    >
                        <option value=''></option>
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                    </select>
                </label>
                <label>
                State
                    <select 
                        value={region}
                        onChange={onRegionChanged}
                        required>
                        <option value=""></option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                </label>
                <button className="FormSubmitButton">Create New User</button>
            </form>
            </div>
            <VSLoginFooter />
        </>
    )

    return content
}
export default NewUserForm