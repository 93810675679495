import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import '../multiForms.css';
import axios from "axios";

const VSSVLHost = () => {

    const navigate = useNavigate()

    const [sVLName, setSVLName] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [zip, setZip] = useState("");


    const onSVLNameChanged = (e) => setSVLName(e.target.value);
    const onEmailChanged = (e) => setEmail(e.target.value);
    const onPhoneChanged = (e) => setPhone(e.target.value);
    const onAddressChanged = (e) => setAddress(e.target.value);
    const onCityChanged = (e) => setCity(e.target.value);
    const onRegionChanged = (e) => setRegion(e.target.value);
    const onZipChanged = (e) => setZip(e.target.value);

    const body = {
        sVLName: sVLName,
        email: email,
        phone: phone,
        address: address,
        city: city,
        region: region,
        zip: zip
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("https://volleysub-api.onrender.com/host", {
            sVLName: sVLName,
            email: email,
            phone: phone,
            address: address,
            city: city,
            region: region,
            zip: zip
        })
        .then(
            navigate('/sent')
        );
    }

    return(
        <div>
        <VSLoginHeader />
            <div className="SVLHostFormContainer">
            <div className="SVLHostTitle">League Hosting</div>
            <div className="SVLHostSubtitle">Need a League Management System?</div>
            <div className="SVLHostSubtitle2">
                <p>Fill out the below form and host your volleyball leagues with Volleysub!</p>
            </div>
            <form className="SVLHostForm" onSubmit={handleSubmit}>
                <label> 
                Company Name
                    <input 
                        name="company"
                        value={sVLName}
                        type="text"
                        onChange={onSVLNameChanged}
                        required
                    />
                </label>
                <label>
                Email
                    <input
                        name="email"
                        value={email}                             
                        type="email"
                        onChange={onEmailChanged}
                        required
                    />
                </label>
                <label>
                Phone Number
                    <input 
                        name="phone"
                        value={phone}
                        type="tel"
                        onChange={onPhoneChanged}
                        required
                    />
                </label>
                <label>
                Address
                    <input 
                        name="address"
                        value={address}
                        type="text"
                        onChange={onAddressChanged}
                        required
                    />    
                </label>
                <label>
                City
                    <input 
                        name="city"
                        value={city}
                        type="text"
                        onChange={onCityChanged}
                        required
                    />    
                </label>
                <label>
                State
                    <select 
                        value={region}
                        onChange={onRegionChanged}
                        required>
                        <option value=""></option>
                        <option value="AK">AK</option>
                        <option value="AL">AL</option>
                        <option value="AR">AR</option>
                        <option value="AZ">AZ</option>
                        <option value="CA">CA</option>
                        <option value="CO">CO</option>
                        <option value="CT">CT</option>
                        <option value="DC">DC</option>
                        <option value="DE">DE</option>
                        <option value="FL">FL</option>
                        <option value="GA">GA</option>
                        <option value="HI">HI</option>
                        <option value="IA">IA</option>
                        <option value="ID">ID</option>
                        <option value="IL">IL</option>
                        <option value="IN">IN</option>
                        <option value="KS">KS</option>
                        <option value="KY">KY</option>
                        <option value="LA">LA</option>
                        <option value="MA">MA</option>
                        <option value="MD">MD</option>
                        <option value="ME">ME</option>
                        <option value="MI">MI</option>
                        <option value="MN">MN</option>
                        <option value="MO">MO</option>
                        <option value="MS">MS</option>
                        <option value="MT">MT</option>
                        <option value="NC">NC</option>
                        <option value="ND">ND</option>
                        <option value="NE">NE</option>
                        <option value="NH">NH</option>
                        <option value="NJ">NJ</option>
                        <option value="NM">NM</option>
                        <option value="NV">NV</option>
                        <option value="NY">NY</option>
                        <option value="OH">OH</option>
                        <option value="OK">OK</option>
                        <option value="OR">OR</option>
                        <option value="PA">PA</option>
                        <option value="RI">RI</option>
                        <option value="SC">SC</option>
                        <option value="SD">SD</option>
                        <option value="TN">TN</option>
                        <option value="TX">TX</option>
                        <option value="UT">UT</option>
                        <option value="VA">VA</option>
                        <option value="VT">VT</option>
                        <option value="WA">WA</option>
                        <option value="WI">WI</option>
                        <option value="WV">WV</option>
                        <option value="WY">WY</option>
                    </select>
                </label>
                <label>
                ZIP Code
                    <input 
                        name="zip"
                        value={zip}
                        type="text"
                        onChange={onZipChanged}
                        required
                    />
                </label>
                <input className="FormSubmitButton" type="submit"/>
            </form>
            </div>
            <VSLoginFooter />
        </div>
    );
}

export default VSSVLHost;
