import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axios from "axios";
import '../multiForms.css';

const VSMultiContact = () => {

    const {firstName, lastName, email} = useAuth();
    const [body, setBody] = useState("");

    const onChangedBody = e => setBody(e.target.value)

    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("https://volleysub-api.onrender.com/playerContact", {
            body: body,
            name: (firstName && lastName),
            email: email
        }).then(
            navigate('../playersent')
        );
    }

    return(
        <div>
            <div className="MultiContactFormContainer">
                <div className="MultiContactFormTitle">Contact Volleysub</div>
                <form className="MultiContactForm" onSubmit={handleSubmit}>
                <label className="MultiContactTextareaLabel">
                Let us know any questions you have or how we can improve Volleysub:
                    <textarea 
                        className="MultiContactTextarea"
                        value={body}
                        onChange={onChangedBody}
                        required
                        />
                </label>             
                <input className="FormSubmitButton" type="submit"/>
                </form>
            </div>
        </div>
    );
}

export default VSMultiContact;