import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useTitle from '../../hooks/useTitle'
import { useGetLeaguesQuery } from '../leagues/leagueApiSlice'
import { useGetNotesQuery } from '../notes/notesApiSlice'
import moment from 'moment'
import '../../playerSide.css' 


const Welcome = () => {

    const { username, isSVL, isAdmin, id, sub } = useAuth()
    useTitle(`Volleysub: ${username}`)

    const navigate = useNavigate()

    const dateNow = new Date()
    const offset = dateNow.getTimezoneOffset()
    const hours = offset / 60
    const { note } = 
    useGetNotesQuery("notesList", {
        selectFromResult: ({ data }) => ({
            note: data?.entities
        }),
    })

    const { league } = 
    useGetLeaguesQuery("leaguesList", {
        selectFromResult: ({ data }) => ({
            league: data?.entities
        })
    })

    useEffect(() => {
        if(isAdmin) {
            navigate('/dash/profile', { replace: true})
        }
    })

    useEffect(() => {
        if(isSVL) {
            navigate('/dash/sessions', { replace: true})
        }
    })


    const subRequests = () => {
    if(!note) {
        return <p>No Sub Requests</p>
    } else {
    const filteredNotes = Object.values(note).filter((status) => status.status === "Open" && status.completed === false && status.recipient === "Anyone")
    const sortedNotes = filteredNotes.sort((a,b) => a.date > b.date ? 1 : -1)
    const notesArray = sortedNotes.map((notes) => {
        const offsetDate = moment(notes.date).add(hours, 'hours')
        const date = new Date(offsetDate).toLocaleString('en-US', { timezone: 'America/New_York', day: 'numeric', month: 'short', year: 'numeric', hour: "2-digit", minute: "2-digit"})
        const navToNotes = (e) => {
            e.preventDefault()
            navigate(`../notes/${notes.id}`)
        }
        return (
        <>
            <p className="dash-subs" onClick={navToNotes}><b>{notes.svl}</b> - {notes.skillLevel} {notes.format}<br />{date}</p>
        </>)
    })
        return notesArray
    }}

    const leaguesInReg = () => {
        if(!league) {
            return <p>No Leagues</p>
        } else {
            const dateNow = new Date()
            const filteredLeagues = Object.values(league).filter((startDate) => Date.parse(startDate.startDateTime) > Date.parse(dateNow))
            const sortedLeagues = filteredLeagues.sort((a,b) => a.startDateTime > b.startDateTime ? 1 : -1)
            const leaguesArray = sortedLeagues.map((leagues) => {
                const date = new Date(leagues.startDateTime).toLocaleString('en-US', { timezone: 'UTC', day: 'numeric', month: 'short', year: 'numeric' })
                const navToLeagues = (e) => {
                    e.preventDefault()
                    navigate(`../leagues/${leagues.id}`)
                }
                return (
                    <p className='dash-leagues' onClick={navToLeagues}>{leagues.sessionName}: <b>{leagues.svlName}</b> - {leagues.day} {leagues.skillLevel} {leagues.format}<br />Start Date: {date}</p>
                )
            })
            return leaguesArray
        }
    }

    const currentLeagues = () => {
        if(!league) {
            return <p>No Leagues</p>
        } else {
            const dateNow = new Date()
            const filteredLeagues = Object.values(league).filter((startDate) => (Date.parse(startDate.startDateTime) < Date.parse(dateNow)) && (startDate.active === true))
            const sortedLeagues = filteredLeagues.sort((a,b) => a.startDateTime > b.startDateTime ? 1 : -1)
            const leaguesArray = sortedLeagues.map((leagues) => {
                const navToLeagues = (e) => {
                    e.preventDefault()
                    navigate(`../leagues/${leagues.id}`)
                }
                return (
                    <p className='dash-leagues' onClick={navToLeagues}>{leagues.sessionName}: <b>{leagues.svlName}</b> - {leagues.day} {leagues.skillLevel} {leagues.format}</p>
                )
            })
            return leaguesArray
        }
    }

    const subLogic = () => {
        const navToSubs = (e) => {
            e.preventDefault()
            navigate(`../users/${id}`)
        }
        if (!sub) {
            return <p className="dash-subs" onClick={navToSubs}>Become a Sub</p>
        } else {
            return <p className="dash-subs" onClick={navToSubs}>Edit My Sub Info</p>
        }
    }

    const navToNotes = (e) => {
        e.preventDefault()
        navigate(`../notes`)
    }

    const navToMyNotes = (e) => {
        e.preventDefault()
        navigate(`../notes/notifications`)
    }
    const navToNewNotes = (e) => {
        e.preventDefault()
        navigate(`../notes/new`)
    }
    const navToAllLeagues = (e) => {
        e.preventDefault()
        navigate(`../leagues`)
    }
    const navToAllSubs = (e) => {
        e.preventDefault()
        navigate(`../subs`)
    }

    const content = (
        <section className="welcome">

        <h1 className='dash-title'>Welcome {username}!</h1>

            <div className='dash-content'>

                <div className='homepage-desktop'>
                <div className='dash-actions'>
                    <h1 className='player-dash-headers'>Sub Requests</h1>
                    <div className='sub-requests'>
                    {subLogic()}
                    <p className="dash-subs" onClick={navToAllSubs}>All Subs</p>
                    <p className="dash-subs" onClick={navToNotes}>All Open Sub Requests</p>
                    <p className="dash-subs" onClick={navToMyNotes}>My Sub Requests</p>
                    <p className="dash-subs" onClick={navToNewNotes}>Create Sub Request</p>
                    <h4 className='league-subheaders'>Subs Needed</h4>
                        {subRequests()}
                    </div>
                </div>
                </div>

                <div className='calendar-league-container'>
                    <div className='leagues-container'>
                        <h1 className='player-dash-headers'>Leagues</h1>
                        {/* <div className='player-leagues'> */}
                            <div className='current-leagues'>
                            <p className="dash-subs" onClick={navToAllLeagues}>All Leagues</p>
                                <h4 className='league-subheaders'>Leagues in Registration</h4>
                                    {leaguesInReg()}
                                <h4 className='league-subheaders'>Current Leagues</h4>
                                    {currentLeagues()}
                            </div>
                        {/* </div> */}
                    </div>
                </div>

                <div className='homepage-mobile'>
                <div className='dash-actions'>
                    <h1 className='player-dash-headers'>Sub Requests</h1>
                    <div className='sub-requests'>
                    {subLogic()}
                    <p className="dash-subs" onClick={navToAllSubs}>All Subs</p>
                    <p className="dash-subs" onClick={navToNotes}>All Open Sub Requests</p>
                    <p className="dash-subs" onClick={navToMyNotes}>My Sub Requests</p>
                    <p className="dash-subs" onClick={navToNewNotes}>Create Sub Request</p>
                    <h4 className='league-subheaders'>Subs Needed</h4>
                        {subRequests()}
                    </div>
                </div>
                </div>

            </div>
        </section>
    )

    return content
}

export default Welcome